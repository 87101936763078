@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 5px;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 13px 10px 5px 10px;
  font-weight: 200;
}

.priceBox {
  position: absolute;
  color: var(--matterColor);
  background-color: rgba(228, 233, 237, .8);
  padding: 1px 2px 1px 2px;
  right: 10px;
  top: 0px;
  font-weight: var(--fontWeightBold);
  border-radius: var(--borderRadiusMobileSearch);
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
