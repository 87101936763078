@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.root input {
  border-radius: var(--InputFormFieldBorderRadius);
  ;
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.passwordInputContainer {
  position: relative;
}

.passwordInputVisibleButton {
  position: absolute;
  display: block;
  top: 0;
  right: 10px;
  border: 0;
  width: 36px;
  height: 36px;
}

.eyeViewIcon {
  width: 24px;
  height: 24px;
}



.password {
  /* @apply --marketplaceModalPasswordMargins; */
}

.password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px inset var(matterColorFilter);
  border-radius: 25px;
  height: 40px;
  margin-bottom: 5px;
}

.password input {
  border: none;
  background-color: transparent;
  /* margin-top: 23px; */
  height: 40px;
  width: 300px;
}

.password input div {}

.password svg {
  border: none;
  background: none;
  padding-right: 10px;
}

.label {
  font-weight: var(--fontWeightLight);
}

.checkBoxTitle {
  margin-bottom: 0px;
}

.checkBoxWrapper {}

.checkBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 40px;
  /* margin-bottom: 10px;*/
}

/* .checkBox {
  margin-right: 40px; */
/* height: 30px;
  width: 40px;
  margin-left: 10px;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
  font-size: 10px;
  border:  1px solid var(--matterColorNegative);

  &:hover {
    background-color: var(--marketplaceColor);
    color: white;
  } */
/* } */

/* .checkBox input {
  height: 30px;
  width: 40px;
  margin-left: 10px;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
  font-size: 10px;
  border:  1px solid var(--matterColorNegative);
} */


.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  text-align: left;
  line-height: normal;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}


.buttonWrapper {
  display: flex;
  justify-content: center;
}