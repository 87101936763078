@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.buttonFilter {
    @apply --marketPlaceButtonStyleFilter;
}

.buttonFilterActive {
    @apply --marketPlaceButtonStyleFilter;

    &:hover {
        background-color: var(--matterColorFilter)
    }
}