.root {
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.list {
  margin: 0;

  @media (--viewportMedium) {
    display: flex;
    flex-wrap: wrap;
  }
}

.list li {
  min-width: 250px;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 2px 0;
  padding-right: 10px;
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.item label {
  margin-right: 15px;
}