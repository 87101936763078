@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';


.price {
    color: var(--marketplaceColor)
}

.requestPayment {
    flex-shrink: 0;
    padding: 0 24px 24px 24px;
    width: 100%;
  
    @media (--viewportMedium) {
      padding: 0;
      width: 100%;
    }
}