@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}



.heroContent {
  @apply --jumbotron;
  background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('../../assets/background-1440.jpg');
}

.heroMainTitle {
  @apply --jumbotronTitle;
}

.heroSubTitle {
  @apply --jumbotronSubtitle
}



.sectionHeroSearch {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.sectionHeroSearchInput input {
  /* border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; */
  margin-right: 0px;

  @media (--viewportMedium) {
    min-width: 500px;
  }
}


.buttonSearch {
  height: 50px;
  min-width: 180px;
  border-radius: 10px;
}