.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.title {
  margin-bottom: 24px;
}

.error {
  color: var(--failColor);
}

.calendarWrapper {
  flex-grow: 1;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }

  .title {
    margin-bottom: 32px;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }

  .submitButton {
    display: inline-block;
    width: 241px;
    margin-top: 86px;
  }
}


.timeWrapper {
  @media (--viewportLarge) {
    display: flex;
  }

  justify-content: space-between;
  max-width: 100%;

  @media (--viewportMedium) {
    max-width: 75%;
  }
}

.timeWrapper>div {
  width: 100%;
  margin-bottom: 20px;
}

.timeWrapper>div:first-child {
  margin-right: 5px;

  @media (--viewportLarge) {
    margin-right: 20px;
  }
}

.timeWrapper>div>label {
  white-space: nowrap;
}