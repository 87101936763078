@import '../../styles/propertySets.css';

.root {
  
}

.password {
  @apply --marketplaceModalPasswordMargins;
  margin-bottom: 24px;
  margin-top: 10px;
}

.buttonWrapper {
  margin: 0 auto;
}