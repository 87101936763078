@import '../../styles/propertySets.css';

.root {
  /* Fixed on top of everything */
  position: fixed;
  bottom: 0;
  z-index: 1000;
  /* height: 100px; */
  /* Dimensions */
  width: 100vw;
  padding: 5px 0;

  /* Layout */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* Colors */
  background-color: rgba(255,255,255,.9);
  color: var(--matterColor);

  /* Cover Topbar on desktop */
  @media (--viewportMedium) {
    padding: 8px 0;
  }
}

.message {
  @apply --marketplaceH5FontStyles;
  margin-left: 24px;
}

.cookieLink {
  color: var(--matterColor);
  border-bottom: 1px solid var(--matterColorNegative);

  &:hover {
    text-decoration: none;
  }
}

.continueBtn {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Dimensions */
  padding: 3px 12px 3px 12px;
  margin: 0 24px 0 6px;

  background-color: transparent;
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin: 0 24px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}

.wrapperSwitch {
  display: flex;
  justify-content: space-between;
}

.switchContainer {
 
}

.title {
  font-weight: var(--fontWeightRegular);
  font-size: 25px;
  margin-top: 0;
}