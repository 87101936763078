.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.mainTitle {
  margin-top: 0px;
  font-weight: var(--fontWeightBold);
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;

}

.title input {
  border-radius: 4px;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.description textarea {
  border-radius: 4px;
  height: 100px;
}

.category {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
  }
}

.sectionRadio {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin-bottom: 30px;
}

.categoryWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.categoryCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
}

.titleIconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* width: 200px; */
}