.root {
}

.root label {
  font-weight: var(--fontWeightLight);
}

.select {
  padding-left: 0px;
  padding-right: 15px;
  border-color: var(--matterColorAnti);
}


.selectSuccess {
  color: var(--matterColor);
  /* border-bottom-color: var(--successColor); */
}

.selectError {
  /* border-bottom-color: var(--failColor); */
}
