.root {
  margin-top:150px;
  @media (--viewportLarge) {
    margin-top:0px;
  }
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
}

.mapRoot {
  width: 100%;
  height: 100%;
}

.reusableMap {
  width: 100%;
  height: 100%;
}

.defaultMapLayout {
  /* position: fixed; */
  top: 0;
  right: 0;
  /* width: 50vw; */
  /* height: 100vh; */
}

/**
 * When reusable map is attached right to the body it's hidden.
 * Specificity rule is added to overwrite positioning coming from props.className
 */
body > .reusableMapHidden {
  /* position: absolute; */
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  opacity: 0;

  @media (--viewportMedium) {
    top: -1000px;
    left: -1000px;
    right: auto;
  }
}
