@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root{
  margin-right: 20px;
}

.input {
  border-radius: 50px;
  border-color: var(--matterColorFilter);
  height: 36px;
}
