@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';


.grid {
   text-align: center;
}

.imageWrapper {
    width: auto;
    height: 250px;
    border-radius: 4px;
    transition: var(--transitionStyleButton);
  
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowSectionLocationHover);
    }
}
  

.locationImage {
    cursor: pointer;
    border-radius: 4px;
    object-fit: cover; 
    width: 100%;
    height: 100%;
}
  