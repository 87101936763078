@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 10px;
  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
  
}

.priceBreakdownContainer {
  padding: 0px;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    /* padding: 0 24px; */
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 14px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  
  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.wrapperBoxForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  height: 40px;
}

.inputBoxForm {
  border: 1px solid var(--matterColorAnti);
  max-width: 120px;
  border-radius: 25px;
  padding-left: 5px;
  height: 25px;
}

.wrapperBoxFormText {
  width: 110px;
  line-height: 18px;
}
.wrapperBoxFormText span {
  color: var(--matterColorAnti);
}

.buttonBoxForm {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: 1px solid var(--matterColorAnti);
  font-size: 20px;
  &:hover {
    background-color: var(--matterColorRollover);
  }
}

.wrapperBoxFormTip {
  margin-top: 0px;
  font-size: 13px;
  line-height: 18px;
  color: var(--matterColorAnti);
}

.wrapperBoxForm input[type=number]::-webkit-inner-spin-button, 
.wrapperBoxForm input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
}


input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.caption {
  color: var(--matterColorAnti);
  font-style: italic;
  font-size: 13px;
  margin-top: 0px;
}

.eventWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}