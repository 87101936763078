@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.root  input  {
  border-radius: var(--InputFormFieldBorderRadius);;
}

/* .password {
  @apply --marketplaceModalPasswordMargins;
} */
.passwordInputContainer{
  position: relative;
}

.passwordInputVisibleButton{
  position: absolute;
  top: 0;
  display: block;
  right: 10px;
  border: 0;
  width: 36px;
  height: 36px;
}

.eyeViewIcon{
  width: 24px;
  height: 24px;
}

.password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px inset var(matterColorFilter);
  border-radius: 25px;
  height: 40px;
  margin-bottom: 5px;
}

.password input {
  border: none;
  background-color: transparent;
  /* margin-top: 23px; */
  height: 40px;
  width: 300px;
}

.password svg {
  border: none;
  background: none;
  padding-right: 10px;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}


.signUpLink {
  color: var(--matterColor);
  font-size: 14px;
  text-align: center;
}

.buttonWrapper{
  display: flex;
  justify-content: center;
}

.label {
  font-weight: var(--fontWeightLight);
}

.error {
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  color: red;
  margin-top: 0px;

}
