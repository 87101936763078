@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';



.logo{
  margin-left: 20px;
  @media (--viewportMedium) {
    margin-left: 40px;
  }
}
.newListing{
  margin-right: 20px;
  margin-top: 20px;
  font-weight: var(--fontWeightMedium);
  font-size: 15px;

  @media (--viewportMedium) {
    margin-right: 40px;
    font-size: 18px;

  }
}

.list {
  padding-left:  0px;
  @media (--viewportMedium) {

  }
}

.list li {
  padding-left:  0px;

  @media (--viewportMedium) {
    padding-left:  0px;
  }
}

.list li a{
  padding-left:  20px;
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  transition: all 0.1s ease;

  @media (--viewportMedium) {
    padding-left: 30px;
    font-size: 22px;

    &:hover{
      padding-left: 30px;
    }
  }
}

.h2 {
  padding-left:  20px;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 0px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    padding-left: 40px;
  }
}

.menuItemBorder  {
    color: var(--matterColor);
    padding-left: 10px;
    transition: border-left-color 2s linear;

    @apply --marketplaceTabNavHorizontalFontStyles;
    
    border-left-style: solid;
    border-left-width: 8px;
    border-left-color: white;

    &:hover{
      border-left-color: var(--marketplaceColor);
    }
    /* Font */
}

.menuItemSign{
  /* Font */
  margin-left: 20px;
  margin-right: 20px;


  @media (--viewportMedium) {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.menuItemSign span {
  font-size: 21px;
  white-space: nowrap;
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    font-size: 25px;
  }
}

.header {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoutButton{
  color:  var(--marketplaceColor);
  padding-left: 20px;
  border: none;
  cursor: pointer;
  border-left-style: solid;
    border-left-width: 8px;
    border-left-color: white;
  &:hover{
    border-left-style: solid;
    border-left-width: 8px;
    border-left-color: var(--marketplaceColor);
  }
}



.hide {
  display: block;
  padding-bottom: 10px;
  @media (--viewportMedium) {
    display: none;
  }
}

.closeBtn {
  position: absolute !important;
  right: 10px;
}