@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.buttonFilter {
    @apply --marketPlaceButtonStyleFilter;
    white-space: nowrap;
}

.buttonFilterActive {
    @apply --marketPlaceButtonStyleFilter;
    white-space: nowrap;

    &:hover {
        background-color: var(--matterColorFilter)
    }
}

.checkboxes {
    display: flex;
    justify-content: start;
    align-items: center;
    vertical-align: middle;
    word-wrap: break-word;

}


input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}

input[type=checkbox] {
    -border-radius: 4px;

    background: #fff;
    border: 2px solid var(--matterColorAnti);
}

/* added content with a unicode check */
input[type="checkbox"]:checked:before {
    content: "\2713";
    color: white;
    font-size: 21px;
}

input[type="checkbox"]:checked {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
}

.checkboxesInput {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    word-wrap: break-word;
    width: 25px;
    height: 25px;
    margin-right: 20px;

}