@import '../../styles/propertySets.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.tabsContainer h1 {
  display: none;

  @media (--viewportMedium) {
    display: block;

  }
}

.nav::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;
  min-width: 250px;

  @media (--viewportLarge) {
    padding: 24px;
    padding-right: 0px;
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 58px 0 82px calc((100% - 1056px) / 2); */
  }
}

.tabTitle {
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
}

.tab a{
  font-weight: var(--fontWeightLight);
}

.tab {
  margin-left: 16px;
  white-space: nowrap;


  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  flex-grow: 1;

  @media (--viewportLarge) {
    padding: 38px 36px 82px 59px;
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 38px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}
