.root {


}

.label {
  font-weight: var(--fontWeightLight);
}

.input {
  font-weight: var(--fontWeighRegular);
}

.inputSuccess {
  /* border-bottom-color: var(--successColor); */
}

.inputError {
  /* border-bottom-color: var(--failColor); */
  font-weight: var(--fontWeightLight);
}

.textarea {
}
