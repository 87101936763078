.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.title {
    margin-bottom: 24px;
}

.root input {
    border-radius: 4px;
}

.error {
    color: var(--failColor);
}

.sectionAccess {
    display: flex;
    justify-content: space-between;
    max-width: 150px;
}

.submitButton {
    margin-top: auto;
    margin-bottom: 24px;
    flex-shrink: 0;
}

@media (--viewportMedium) {
    .root {
        padding-top: 2px;
        margin-top: 0px;
    }
    .title {
        margin-bottom: 32px;
    }
}

@media (--viewportLarge) {
    .calendarWrapper {
        flex-grow: 0;
        margin-bottom: 24px;
    }
    .submitButton {
        display: inline-block;
        width: 241px;
        margin-top: 86px;
    }
}
  